<template>
  <div class="my-project-requests">
    <router-view @dashboard-event="(et) => $emit('dashboardEvent', et)"></router-view>
  </div>
</template>

<script>
export default {
  name: "MyProjectRequests"
}
</script>
